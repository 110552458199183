import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '@environments/environment';
import {
  NotificationGroupListModel,
  NotificationGroupModel,
  NotificationGroupQueryModel,
  NotificationListModel,
  Notification,
  NotificationQFModel,
  NotificationSettings,
  NotificationStatus,
} from 'ngx-q360-lib';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.baseUrl = environment.apiUrl;
    this.apiEndpoint = 'notification';
  }

  private makeTasksRequest(
    method: string,
    endpoint: string,
    params?: { [key: string]: any } | undefined,
    responseType?: 'json',
  ): Observable<any> {
    return this.makeRequest(method, endpoint, params, responseType).pipe(
      catchError((error) => this.handleError(error)),
    );
  }
  private handleError(error: any): any {
    return throwError(error);
  }

  getNotifications(queryFilter?: NotificationQFModel): Observable<NotificationGroupListModel> {
    return this.makeTasksRequest('GET', 'notification', queryFilter);
  }

  getNotificationFromGroup(queryFilter: NotificationGroupQueryModel): Observable<NotificationListModel> {
    return this.makeTasksRequest('GET', 'notification/FromGroup', queryFilter);
  }

  getNotificationGroup(groupId: string): Observable<NotificationGroupModel> {
    return this.makeTasksRequest('GET', `notification/group/${groupId}`);
  }

  markNotificationAsRead(notification: Notification): Observable<unknown> {
    return this.makeTasksRequest('POST', `notification/${notification.id}/MarkAsRead`);
  }

  markAllAsRead(): Observable<unknown> {
    return this.makeTasksRequest('POST', 'notification/MarkAllAsRead');
  }

  markGroupAsRead(groupId: string): Observable<unknown> {
    return this.makeTasksRequest('POST', `notification/Group/${groupId}/MarkAsRead`);
  }

  delete(id: string): Observable<unknown> {
    return this.makeTasksRequest('DELETE', `notification/${id}`);
  }

  deleteAll(): Observable<unknown> {
    return this.makeTasksRequest('DELETE', `notification/deleteall`);
  }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.makeTasksRequest('GET', 'NotificationSettings');
  }

  updateNotificationSettings(payload: Partial<NotificationSettings>): Observable<NotificationSettings> {
    return this.makeTasksRequest('PATCH', 'NotificationSettings', payload);
  }

  getNotificationStatus(): Observable<NotificationStatus> {
    return this.makeTasksRequest('GET', 'Notification/Status');
  }
}
